import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const GUARANTEE_LOCALES = {
	WOLTAIR_CZ: 'prodlouzena-zaruka',
	WOLTAIR_PL: 'rozszerzona-gwarancja',
	WOLTAIR_DE: null,
	WOLTAIR_IT: null
}

export const GUARANTEE = GUARANTEE_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return GUARANTEE === param
}) satisfies ParamMatcher
