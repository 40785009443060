import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const CALL_ME_LOCALES = {
	WOLTAIR_CZ: 'zavolejte-mi',
	WOLTAIR_PL: 'zadzwon',
	WOLTAIR_DE: 'anruf-anfordern',
	WOLTAIR_IT: 'chiamatemi'
}

export const CALL_ME = CALL_ME_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return CALL_ME === param
}) satisfies ParamMatcher
