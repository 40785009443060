import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const PRIVACY_POLICY_LOCALES = {
	WOLTAIR_CZ: 'zasady-ochrany-osobnich-udaju',
	WOLTAIR_PL: 'informacje-o-przetwarzaniu-danych-osobowych',
	WOLTAIR_DE: 'datenschutzerklaerung',
	WOLTAIR_IT: 'dati-personali'
}

export const PRIVACY_POLICY = PRIVACY_POLICY_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return PRIVACY_POLICY === param
}) satisfies ParamMatcher
